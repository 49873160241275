import ReactDOM from 'react-dom'

import 'assets/styles/vendors.css'
import 'assets/styles/root.css'
import App from './bootstrap/App'
import Capsule from './bootstrap/Capsule'
import Provider from './bootstrap/Provider'
import Router from './bootstrap/Router'
import reportWebVitals from './reportWebVitals'

ReactDOM.render(
  <App>
    <Capsule>
      <Provider>
        <Router />
      </Provider>
    </Capsule>
  </App>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
