import Button from '@zera-admin/button'
import EmptyState from '@zera-admin/empty-state'
import Icon from '@zera-admin/icon'

import NotFoundImage from 'assets/images/not-found.png'
import EmptyLayout from 'layouts/empty'

import { NotFoundProps } from './types'

const NotFound: React.FunctionComponent<NotFoundProps> = ({
  history,
  match,
}) => {
  const primaryAction = (
    <Button
      iconBefore={<Icon name="arrow-left" size="small" label="Turn back" />}
      onClick={() => history.goBack()}
    >
      Turn back
    </Button>
  )

  return (
    <EmptyLayout>
      <EmptyState
        title="404. That’s an error."
        description={`The requested URL ${match.url} was not found on this server.`}
        image={{
          src: NotFoundImage,
        }}
        actions={primaryAction}
      />
    </EmptyLayout>
  )
}

export default NotFound
