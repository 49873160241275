import { lazy } from 'react'

import { RouteProps } from 'app/shared/types'

const Home = lazy(() => import('./home'))
const SignIn = lazy(() => import('./membership/signin'))
const SignOut = lazy(() => import('./membership/signout'))
const TweetList = lazy(() => import('./tweets/Tweets'))
const Users = lazy(() => import('./users/Users'))

const routes: RouteProps[] = [
  {
    component: TweetList,
    path: ['/', '/home', '/dashboard'],
  },
  {
    component: SignIn,
    path: '/signin',
    auth: 'public',
  },
  {
    component: SignOut,
    path: ['/signout', '/signout/:uid'],
    auth: 'public',
  },
  {
    component: TweetList,
    path: '/tweets',
  },
  {
    component: Users,
    path: '/users',
  },
]

export default routes
