import React, { useEffect, useState } from 'react'

import NoInternetConnection from 'components/no-internet-connection'

const App: React.FunctionComponent = ({ children }) => {
  const [onLine, setOnLine] = useState(window.navigator.onLine)

  const handleOffline = () => {
    setOnLine(false)
  }

  const handleOnline = () => {
    setOnLine(true)
  }

  useEffect(() => {
    window.addEventListener('offline', handleOffline)
    window.addEventListener('online', handleOnline)

    return function cleanup() {
      window.removeEventListener('offline', handleOffline)
      window.removeEventListener('online', handleOnline)
    }
  }, [])

  if (onLine) {
    return <React.StrictMode>{children}</React.StrictMode>
  }

  return <NoInternetConnection />
}

export default App
