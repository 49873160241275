import { Action, State } from './types'

import { ADD_FLAGCARD, REMOVE_FLAGCARD } from './constants'

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ADD_FLAGCARD:
      return [...state, action.payload]
    case REMOVE_FLAGCARD:
      return state.filter((data) => data.id !== action.payload)
    default:
      return state
  }
}

export default reducer
