import { Link } from 'react-router-dom'
import { LinkComponent } from '@zera-admin/link'

const CustomLink: LinkComponent = ({ href, children, testId, ...rest }) => {
  if (href && href.indexOf('http') !== 0) {
    return (
      <Link to={href} {...rest}>
        {children}
      </Link>
    )
  }

  return (
    <a href={href} data-testid={testId} {...rest}>
      {children}
    </a>
  )
}

export default CustomLink
