import { createContext, useContext, useReducer } from 'react'

import { State, FlagcardContextProps } from './types'
import * as actions from './actions'
import reducer from './reducer'

const initialState: State = []

export const FlagcardContext = createContext<FlagcardContextProps>({
  add: () => null,
  remove: () => null,
  state: initialState,
})

export const FlagcardProvider: React.FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <FlagcardContext.Provider
      value={{
        add: (data) => dispatch(actions.add(data)),
        remove: (id) => dispatch(actions.remove(id)),
        state,
      }}
    >
      {children}
    </FlagcardContext.Provider>
  )
}

export const useFlagcardContext = (): FlagcardContextProps => {
  return useContext(FlagcardContext)
}
