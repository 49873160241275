import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'

import NotFound from 'pages/not-found'
import routes from 'pages/routes'
import { storage } from '@zera-admin/helpers'

const Router: React.FunctionComponent = () => {
  const middleware = (auth = 'private') => {
    const account = storage.get('token')

    if (!account && auth === 'private') {
      return <Redirect to="/signin" />
    }

    if (account && auth === 'protected') {
      return <Redirect to="/" />
    }
  }
  return (
    <BrowserRouter>
      <Switch>
        {routes.map(({ component: Page, auth, ...route }, index) => (
          <Route
            exact
            key={index}
            render={(props) => middleware(auth) || <Page {...props} />}
            {...route}
          />
        ))}
        <Route path="*" component={NotFound} />
      </Switch>
    </BrowserRouter>
  )
}

export default Router
