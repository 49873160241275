import { Provider as ReduxProvider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { LinkProvider } from '@zera-admin/link'

import { store } from 'app/redux'
import { FlagcardProvider } from 'app/contexts/flagcard'
import { PopupProvider } from 'app/contexts/popup'
import CustomLink from 'components/custom-link'

const Provider: React.FunctionComponent = ({ children }) => {
  const theme = {}

  return (
    <ThemeProvider theme={theme}>
      <LinkProvider component={CustomLink}>
        <ReduxProvider store={store}>
          <FlagcardProvider>
            <PopupProvider>{children}</PopupProvider>
          </FlagcardProvider>
        </ReduxProvider>
      </LinkProvider>
    </ThemeProvider>
  )
}

export default Provider
