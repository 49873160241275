import { Action, FlagcardContextData } from './types'

import { ADD_FLAGCARD, REMOVE_FLAGCARD } from './constants'

export const add = (payload: FlagcardContextData): Action => ({
  type: ADD_FLAGCARD,
  payload,
})

export const remove = (payload: string): Action => ({
  type: REMOVE_FLAGCARD,
  payload,
})
