import styled from 'styled-components'

export const Flex = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 100;
`

export const FlexContent = styled.div`
  width: 100%;
  flex: 1;
  padding: 0 24px;
  box-sizing: border-box;
`

export const FlexFooter = styled.div`
  position: relative;
  width: 100%;
  padding: 24px 0px;
  text-align: center;
  box-sizing: border-box;
`
